import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import ContactFrom from './../../../app.footer';
import contactUsImg from './../../../Data/images/contact_us.jpg';

export default (props) => {

    let headOfficeAddress = (props) => {
        return (
            <>
                <Grid container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start">
                    
                    <Grid  item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom style={{ fontFamily: 'Myriad Pro Bold', textAlign: 'left', marginLeft: '0.5em' }}>
                            Visiting Address Stork:
                        </Typography>
                        <Typography noWrap variant="p" gutterBottom style={{ textAlign: 'center', marginLeft: '1em' }}>
                            Van Deventerlaan 121
                        </Typography>
                        <br />
                        <Typography variant="p" gutterBottom style={{ textAlign: 'center', marginLeft: '1em' }}>
                            3528 AG Utrecht
                        </Typography>
                        <br />
                        <Typography variant="p" gutterBottom style={{ textAlign: 'center', marginLeft: '1em' }}>
                            The Netherlands
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom style={{ fontFamily: 'Myriad Pro Bold', textAlign: 'left', marginLeft: '0.5em' }}>
                            Postal address:
                        </Typography>
                        <Typography noWrap variant="p" gutterBottom style={{ textAlign: 'center', marginLeft: '1em' }}>
                            Van Deventerlaan 121
                        </Typography>
                        <br />
                        <Typography variant="p" gutterBottom style={{ textAlign: 'center', marginLeft: '1em' }}>
                            India,
                        </Typography>
                        <br />
                        <Typography variant="p" gutterBottom style={{ textAlign: 'center', marginLeft: '1em' }}>
                            Pune
                        </Typography>
                    </Grid>
                </Grid>
                {/*  */}
                <Grid container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start">
                    <Typography variant="p" gutterBottom style={{ textAlign: 'center' }}>
                        <ul>
                            <li>Street</li>
                            <li>PO+PS</li>
                        </ul>
                    </Typography>
                </Grid>

            </>
        )
    }

    const headOfficeAddressBody = headOfficeAddress()
    return (
        <>
        <Grid container
        style={{marginBottom: '0px'}}
        >
            <Grid container direction="row"
                justify="center"
                alignItems="center" xs={12} style={{ height: 300, backgroundSize: 'cover', backgroundImage: `url("${contactUsImg}")`, backgroundPosition: 'center' }}>
                <Typography variant="h2" gutterBottom style={{ fontWeight: 700, 'line-height': '60px', textAlign: 'center', color: '#fff' }}>
                    {"Contact Us"}
                </Typography>
            </Grid>

            {/* <Grid container direction="row" justify="center" style={{ margin: 25 }}>
                <Typography variant="h5" gutterBottom style={{ color: '#114675' , fontSize: 35,fontWeight: 500, fontFamily: 'Myriad Pro Regular', textAlign: 'center' }}>
                    {"Head Office"}
                </Typography>
            </Grid> */}
            {/* <Container>
                <Grid item xs={12} lg={12}>
                    <DescriptionCard title={""} body={headOfficeAddressBody} />
                </Grid>
            </Container> */}
        </Grid>

        <ContactFrom mode="showForm" />
        </>
    );
}