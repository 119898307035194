import React from "react";
import {
    Grid, Button, Box, Typography, Tab, Paper, Tabs, ClickAwayListener
} from "@material-ui/core";
import { Link } from "react-router-dom";

import LOGO from '../../Data/images/Logo/snwelLogo_Registered_Office.png';

export default function DesktopNavbar(props) {

    const [selectedTabIndex, setSelectedTabIndex] = React.useState(-1);
    const [disableStatus, setDisableStatus] = React.useState(true);

    const handleChange = (event, newValue) => {
        if (selectedTabIndex === newValue) {
            setDisableStatus(true);
            setSelectedTabIndex(-1);
            return;
        }
        setSelectedTabIndex(newValue);
        disableStatus && setDisableStatus(false);
    };

    const closeMenu = () => {
        
        setDisableStatus(true);
        setSelectedTabIndex(-1);
    }

    const openMenu = (value) => {
        setDisableStatus(false);
        setSelectedTabIndex(value);
    }
    
    const closeOnLeave = (ev) => {
        closeMenu();
    }
    return (
        <>
            <Grid container direction="row"
                component={Paper}
                square
                elivation={15}
                style={{ zIndex: 10, background: 'white', position: 'sticky', top: -10 }}
                justify="center"
                alignItems="flex-end">
                <Grid sm={2} item >
                    <Link to="/" style={{ textAlign: 'center' }}>
                        <img
                            src={LOGO}
                            style={{ display: 'block', width: '55%', margin: "20px 40px 10px" }}
                        />
                    </Link>
                </Grid>
                <Grid sm={10} item >
                    <ClickAwayListener onClickAway={closeMenu} >
                        <Box style={{ height: '100%', flexGrow: 1 }} >
                            <Tabs
                                value={selectedTabIndex}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                TabIndicatorProps={{ style: { backgroundColor: "rgb(34, 159, 212)" } }}
                            >
                                {props.menu.map((menuItem, index) =>
                                    <Tab label={menuItem.name} style={{ color: "#000" }} value={index}
                                        onMouseEnter={() => openMenu(index)}  />
                                )}
                            </Tabs>
                            <Box
                                onMouseLeave={closeOnLeave}
                                className="tab-menu-s"
                                style={{ display: disableStatus ? "none" : "flex", position: 'absolute', zIndex: 5, left: 0, right: 0 }}              >
                                <Paper container component={Grid}
                                    elevation={10}
                                    alignItems="baseline"
                                    style={{ backgroundColor: "#229fd4", padding: '30px 50px', flexGrow: 1 }}
                                >
                                    <Grid container item sm={6} style={{ padding: "20px 0px" }}>
                                        {
                                            props.menu[selectedTabIndex] && props.menu[selectedTabIndex].childs
                                            && props.menu[selectedTabIndex].childs.map(menuChild =>
                                                <Grid item sm={6} style={{ paddingRight: 50 }}>
                                                    <Button
                                                        fullWidth
                                                        disableElevation
                                                        onClick={closeMenu}
                                                        component={Link}
                                                        to={`${menuChild.link}`}
                                                        style={{
                                                            color: "#fff",
                                                            justifyContent: 'left',
                                                            borderRadius: 0,
                                                            borderBottom: 'inset 1px rgba(255,255,255,0.6)',
                                                        }}
                                                    >
                                                        {menuChild.name}
                                                    </Button>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                    <Grid item sm={6} component={Box} container
                                        style={{
                                            borderLeft: 'solid 2px rgba(255, 255, 255, 0.6)',
                                            color: 'white', padding: "20px 0px", paddingLeft: 25
                                        }}>
                                        <Grid item sm={6}>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {props.menu[selectedTabIndex] && props.menu[selectedTabIndex].name}
                                            </Typography>
                                            <Typography variant="body2" className="textSecondary" component="p">
                                                {props.menu[selectedTabIndex] && props.menu[selectedTabIndex].description}
                                            </Typography>
                                            <Button
                                                onClick={closeMenu}
                                                component={Link}
                                                to='/service/Contact_Us'
                                                style={{
                                                    color: "#fff", width: '100%', justifyContent: 'left',
                                                    marginLeft: -5,
                                                    borderRadius: 0,
                                                    marginTop: 35,
                                                    borderBottom: 'inset 1px rgba(255,255,255,0.6)',
                                                }}
                                            >Contact Us</Button>
                                        </Grid>
                                        <Grid item sm={6} style={{ textAlign: 'center', justifyContent: 'center' }}>
                                            <img
                                                style={{ width: 200, height: 200, overflow: 'hidden', borderRadius: '90%', margin: '8px' }}
                                                src={props.menu[selectedTabIndex] && props.menu[selectedTabIndex].img} />

                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        </Box>
                    </ClickAwayListener>
                </Grid>
            </Grid>

        </>
    );
}