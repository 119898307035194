//import image1 from "./../images/service/3d_lazer_scanning_images/image002.jpg";
//import image2 from "./../images/service/3d_lazer_scanning_images/image003.jpg";
import image3 from "./../images/pipe_image/page_blocks/image006.jpg";
import image4 from "./../images/pipe_image/page_blocks/image004.jpg";

import pipeImg1 from "./../images/pipe_image/pipe_1.jpg";
import pipeImg2 from "./../images/pipe_image/pipe_2.jpg";
import pipeImg3 from "./../images/pipe_image/pipe_3.jpg";
import pipeImg4 from "./../images/slider/About_us.jpg";


import pipeImgBanner from './../images/static_page_images/Plant Design-1-snwel Engineering.jpg'

import Container from "@material-ui/core/Container";

// COMPONENTS IMPORT
//import HeroSection from "../../components/shared/hero-section-text";
import HeroSectionImage from "../../components/shared/hero-section-image";
import DescriptionCard from "../../components/shared/description-card";
import DescriptionTextContent from "../../components/shared/description-text-content";
import { Box } from "@material-ui/core";

import {
  ThreeBoxDetail,
  HeroGreyTextSection,
  HeadingText,                                                                        
  AddValue,
  ListGreyTextSection,
} from "../../components/shared/BaseTextComponents";

export default {
  serviceName: "ABOUT US",
  serviceDescription: ``,
  show: true, 
  serviceBackgroundImage: pipeImg4 ,
  serviceSubHeading: ``,
  imageList: ["", ""],
  serviceBody: () => (
    <>

      <HeroGreyTextSection style={{paddingLeft:'100px'}}
        subHeadingTitle="Company Overview"
        paragraphsList={[
          "snwel Engineering®  is an integrated engineering services & solutions company. We offer services & solutions that are simultaneously affordable, sustainable and of high quality.",
          "Leveraging technology, domain knowledge and our robust work planning methodology to ensure an effective delivery model for engineering services projects.",
          "We provide services in the following verticals – Oil & Gas, Petrochemicals, Power, Process, Food Processing, Paper & pulp, Chemical, Infrastructure, Mining, Mineral & Metals, Automotive, Manufacturing, Pharmaceutical, and Heavy engineering"
        ]}
      />


      <Container maxWidth="lg" style={{ padding: "10px",  }}>
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>
      <HeroGreyTextSection
        subHeadingTitle="Company Strategy"
        paragraphsList={[
          "Purpose: To be a leader in the engineering industry by providing enhanced services, relationship and profitability.",

          "Vision: To provide quality services that exceeds the expectations of our esteemed customers. To innovate and enhance in the field of Engineering services, Solutions, Skill Development, Staffing and Product Development with robust planning and technological methodologies.",

           "Mission statement: To satisfy our customer by providing quality services and cost effective solutions with commitment & strong relationship.",

          "Core values We believe in treating our customers with respect and faith• We grow through creativity, invention and innovation.• We integrate honesty, integrity and business ethics into all aspects of our business functioning",
          
        ]}
        
      />
<Container maxWidth="lg" style={{ padding: "10px", }}>
        {/* BODY CONTENT DUMMY ADDED HERE */}
      </Container>
      <HeroGreyTextSection
        subHeadingTitle="Our Service Portfolio"
        paragraphsList={[
          "snwel Engineering® utilizes leading industry trends and best-practices and insists on getting the job done right. snwel Engineering® encourages and supports on- going training, even for experienced engineers.",

          "Whether you’re looking for a team of piping engineers to service your complete piping engineering needs, or supplement your internal manpower and technical expertise, contact us to see how we can help. We’re the go to experts for pipe engineering in Kolkata.",
        ]}
      />

       {/* google from link script    */}
       <button style={{
                  backgroundColor: "rgba(17, 70, 117, .75)",
                  textShadow: '1px 1px 1px #000',
                  color: 'white',
                  marginTop:30,
                  marginLeft:63,
                  // padding: 10,
                  paddingLeft:700, 
                  paddingRight:530,
                  position:"absolute"
                }}
                  >
                <a style={{
                  marginLeft:-140,
                }} href="https://forms.gle/gGJwGCp8RGQkskdy6" id="redirect" target="_blank">Conatct Us</a>
            </button>
    
    </>
  ),
};
