import plant3DEngineering from './../../../Data/images/Process Plant Design_2-snwel-Engineering.jpg'
import coolingTowerWaterEng from './../../../Data/images/Process Plant Design-snwel-Engineering.jpg'
import materialHandling from './../../../Data/images/Material-Handling-System-snwel Engineering.jpg';
import laserScanning from './../../../Data/images/Lidar-Drone-Survey-snwel Engineering.jpg';


import plant3DEngineeringImg from './../../../Data/images/slider/3d_plant_2.jpg';
import materialHandlingImg from './../../../Data/images/slider/material_handling.jpg';
import laserScanningImg from './../../../Data/images/slider/laser_scanning.jpeg';
import waterEngineeringImg from './../../../Data/images/slider/water-engineering.jpg';
import PlantEngineering from './../../../Data/images/slider/Plant-Engineering.jpg';
import tataImg from '../../../Data/images/TataLogo.png';
import snwelImg from '../../../Data/images/Logo/SNWEL_LOGO1.png';

export default {
    "industries": [
        {
            img: plant3DEngineering,
            title: 'Oil & Gas',
            description: 'The oil and gas sector is among the eight core industries in India and plays a major role in influencing the decision-making for all the other important sections of the economy'
        },
        {
            img: plant3DEngineering,
            title: 'Mining & Metals',
            description: ' The metals and mining sector is the industry dedicated to the location and extraction of metal and mineral reserves around the world'
        },
        {
            img: materialHandling,
            title: 'Water Treatment',
            description: 'Water treatment is any process that improves the quality of water to make it more acceptable for a specific end-use.'
        },
        {
            img: laserScanning,
            title: 'Power',
            description: 'This is the Description, text, here goes the description.This is the Description, text, here goes the description.This is the Description, text, here goes the description.'
        },
        {
            img: coolingTowerWaterEng,
            title: 'Process',
            description: 'This is the Description, text, here goes the description.This is the Description, text, here goes the description.This is the Description, text, here goes the description.'
        },
        {
            img: materialHandling,
            title: 'Chemical',
            description: 'This is the Description, text, here goes the description.This is the Description, text, here goes the description.This is the Description, text, here goes the description.'
        },
        {
            img: coolingTowerWaterEng,
            title: 'Manufacturing',
            description: 'This is the Description, text, here goes the description.This is the Description, text, here goes the description.This is the Description, text, here goes the description.'
        },
        {
            img: laserScanning,
            title: 'Infrastructure',
            description: 'This is the Description, text, here goes the description.This is the Description, text, here goes the description.This is the Description, text, here goes the description.'
        },


    ],
    "carousel": [
        {
            "imgLink": plant3DEngineeringImg,
            "searchLink": "/service/3D_Plant_Engineering",
            "title": "3D Plant Engineering",
            "legendColor": "#2ecc71",
            "content": "3D Plant Engineering, Details Goes Here"
        },
        {
            "imgLink": materialHandlingImg,
            "searchLink": "/service/Material_Handling",
            "title": "Material Handling System",
            "legendColor": "#2ecc71",
            "content": "Material Handling System, Details Goes Here, This is the Description, text, here goes the description."
        },
        {
            "imgLink": laserScanningImg,
            "searchLink": "/",
            "title": "Laser Scanning",
            "legendColor": "#2ecc71",
            "content": "Laser Scanning & As-Built Services, Details Goes Here, This is the Description, text, here goes the description."
        },
        {
            "imgLink": waterEngineeringImg,
            "searchLink": "/",
            "title": "Water Engineering",
            "legendColor": "#2ecc71",
            "content": "Cooling Tower & Water Engineering, Details Goes Here, This is the Description, text, here goes the description."
        },
        // {
        //     "imgLink": PlantEngineering,
        //     "searchLink": "/",
        //     "title": "Plant Engineering",
        //     "legendColor": "#2ecc71",
        //     "content": "Cooling Tower & Water Engineering, Details Goes Here, This is the Description, text, here goes the description."

        // }
    ],
    "logoCarousel": [
        {
            slides: [
                waterEngineeringImg,
                waterEngineeringImg,
                waterEngineeringImg,
                waterEngineeringImg,
                waterEngineeringImg
                
                
            ]
        },
       
    ]
}