import "./App.css";
import { Route, Switch } from "react-router-dom";
import Routes from "./components/pages/index";
import { MobileNavbar, RouteStatus } from "./app.navbar";
import DesktopNavbar from "./components/DesktopNavbar"
import Footer from './app.footer';
import { useEffect } from "react";
import { useState } from "react";

import NavbarData from "./Data/navbar.data";


function App() {
  const [screenSize, setScrrenSize] = useState(
    window.innerWidth <= 760 ? "mobilemode" : "desktopmode"
  );
  const resize = () => {
    if (window.innerWidth <= 760) {
      setScrrenSize("mobilemode");
    } else {
      setScrrenSize("desktopmode");
    }
  };
  useEffect(() => {
    window.addEventListener("resize", resize);
  }, []);
  return (
    <>
      {screenSize === "mobilemode" ? (
        <MobileNavbar menu={NavbarData.navbar} />
      ) : (
        <DesktopNavbar menu={NavbarData.navbar} />
      )}
      <RouteStatus />
      <Switch>
        {Routes.map((route) => (
          <Route
            path={route.path}
            key={route.name}
            component={route.component}
            exact={route.isExact}
          />
        ))}
      </Switch>
      <Footer />
    </>
  );
}

export default App;